<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <app-btn @click="dialog = true">
        <v-icon>mdi-account-plus-outline</v-icon> Add New Fare
      </app-btn>
    </v-col>
    <material-card
      icon="mdi-account-group"
      icon-small
      color="primary"
      title="Fare Details"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="fares"
          :search.sync="search"
          :footer-props="{
            showFirstLastPage: true,
          }"
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="650px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="SpecialFareForm">
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.tarifId"
                            :items="_fares"
                            item-text="tarifName"
                            item-value="tarifId"
                            label="Fare"
                            hide-details
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.tarif.flagFall`]="{ item }">
            {{
              currencyFormat(
                item.tarif.flagFall ? item.tarif.flagFall / 100 : "$0.00"
              )
            }}
          </template>
          <template v-slot:[`item.tarif.distanceRate`]="{ item }">
            {{
              currencyFormat(
                item.tarif.distanceRate
                  ? item.tarif.distanceRate / 100
                  : "$0.00"
              )
            }}/km
          </template>
          <template v-slot:[`item.tarif.waitingTimeRate`]="{ item }">
            {{
              currencyFormat(
                item.tarif.waitingTimeRate
                  ? item.tarif.waitingTimeRate / 100
                  : "$0.00"
              )
            }}/minute
          </template>
          <template v-slot:[`item.tarif.startTime`]="{ item }">
            {{
              timeFormat(item.tarif.startTime) +
              " - " +
              timeFormat(item.tarif.endTime)
            }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!-- <v-icon medium color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon> -->
            <v-icon medium color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import moment from "moment";
export default {
  name: "DataTablesView",
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "tarif.tarifName",
      },
      { text: "Flag Fall", value: "tarif.flagFall" },
      { text: "Distance Rate", value: "tarif.distanceRate" },
      { text: "Waiting Time", value: "tarif.waitingTimeRate" },
      { text: "Time Range", value: "tarif.startTime" },
      { text: "Actions", align: "end", value: "actions", sortable: false },
    ],
    statuses: [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ],
    fares: [],
    _fares: [],
    editedIndex: -1,
    editedItem: {
      tarifId: 0,
      vehicleTypeId: 0,
    },
    defaultItem: {
      tarifId: 0,
      vehicleTypeId: 0,
    },
    search: undefined,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add New SpecialFare"
        : "Edit SpecialFare";
    },
    vehicleTypeId() {
      return this.$route.params.id;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    this.$http
      .get("/Tarifs")
      .then((response) => {
        let result = response.data.data;
        if (result) {
          this._fares = result.sort((a, b) => b.tarifId - a.tarifId);
        }
      })
      .catch(() => {});
  },

  methods: {
    initialize() {
      this.$http
        .get(`/VehicleType/${this.vehicleTypeId}/Tarifs`)
        .then((response) => {
          let result = response.data.data;
          if (result) {
            this.fares = result.sort((a, b) => b.tarifId - a.tarifId);
          }
        })
        .catch(() => {});
    },

    editItem(item) {
      this.editedIndex = this.fares.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.fares.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let index = this.editedIndex;
      this.$http
        .delete(`/VehicleType/Tarif/${this.editedItem.vehicleTypeTarifId}`)
        .then((response) => {
          if (response.data.success) {
            this.fares.splice(index, 1);
            this.$toast.success("Fare deleted successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error("Something went wrong.", "Error", {
              position: "topRight",
            });
          }
        })
        .catch(() => {});

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      let index = this.editedIndex;
      if (this.$refs.SpecialFareForm.validate()) {
        if (index > -1) {
        } else {
          this.editedItem.vehicleTypeId = this.vehicleTypeId;
          this.$http
            .post("/VehicleType/Tarif", this.editedItem)
            .then((response) => {
              if (response.data.success) {
                this.$toast.success(response.data.message, "Success", {
                  position: "topRight",
                });
                this.initialize();
              } else {
                this.$toast.error("Something went wrong.", "Error", {
                  position: "topRight",
                });
              }
            });
        }
        this.close();
      }
    },
    currencyFormat(val) {
      return "$" + parseFloat(val).toFixed(2);
    },
    timeFormat(val) {
      return moment("0001-01-01T" + val).format("h:mm a");
    },
  },
};
</script>
