var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"data-tables-view","fluid":"","tag":"section"}},[_c('v-col',{staticClass:"text-right"},[_c('app-btn',{on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-account-plus-outline")]),_vm._v(" Add New Fare ")],1)],1),_c('material-card',{attrs:{"icon":"mdi-account-group","icon-small":"","color":"primary","title":"Fare Details"}},[_c('v-card-text',[_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Search records","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.fares,"search":_vm.search,"footer-props":{
          showFirstLastPage: true,
        }},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"650px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"SpecialFareForm"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm._fares,"item-text":"tarifName","item-value":"tarifId","label":"Fare","hide-details":""},model:{value:(_vm.editedItem.tarifId),callback:function ($$v) {_vm.$set(_vm.editedItem, "tarifId", $$v)},expression:"editedItem.tarifId"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)]},proxy:true},{key:"item.tarif.flagFall",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat( item.tarif.flagFall ? item.tarif.flagFall / 100 : "$0.00" ))+" ")]}},{key:"item.tarif.distanceRate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat( item.tarif.distanceRate ? item.tarif.distanceRate / 100 : "$0.00" ))+"/km ")]}},{key:"item.tarif.waitingTimeRate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat( item.tarif.waitingTimeRate ? item.tarif.waitingTimeRate / 100 : "$0.00" ))+"/minute ")]}},{key:"item.tarif.startTime",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeFormat(item.tarif.startTime) + " - " + _vm.timeFormat(item.tarif.endTime))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"medium":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }